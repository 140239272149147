import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { INodesData } from '../../../../api/api-sdk';
import {
  INodeForm,
  INodeListItem,
  IVariableAlert,
} from '../../../../model/node';

export const NodesActions = createActionGroup({
  source: 'Nodes',
  events: {
    toggleAttribute: props<{ id: string }>(),
    nodesDataUpdated: props<{ nodesData: INodesData[] }>(),
    nodesAndAlertsUpdated: props<{
      nodes: INodeListItem[];
      alerts: IVariableAlert[];
    }>(),
    readingColumnsUpdated: props<{ columns: ColDef[] }>(),
    startActiveNodeScrolling: props<{ nodeId: number }>(),
    scrollActiveNode: props<{ delta: number }>(),
    nodeUpdated: props<{ nodeId: number; form: INodeForm }>(),
    updateNodesData: emptyProps(),
    setNodesSortField: props<{
      sortField: { field: string; direction?: 'asc' | 'desc' };
    }>(),
    setCustomNodesOrder: props<{ nodesOrder: number[] }>(),
    restoreCustomNodesOrder: props<{
      nodesOrder: number[];
      nodesSortField: { field: string; direction?: 'asc' | 'desc' };
    }>(),
    openChartsForNode: props<{ node: INodeListItem }>(),
  },
});
