import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ICSVExportRequest,
  IGetCurrentCamPoint,
  ILocationConfiguration,
  ILocationConfigurationEmail,
  ILocationsData,
  ISaveCamPointPostRequest,
  ISaveCamPointPutRequest,
  ISaveThermalCamPoint,
  IStatusCSVExportResponse,
  IThermalCamSettings,
  ITimeZone,
  IUserSettingsGet,
  IVariableToDisplay,
} from '../../api/api-sdk';
import { ITask } from '../../model/task';

export const AdminActions = createActionGroup({
  source: 'Admin',
  events: {
    initAdmin: emptyProps(),
    locationsUpdated: props<{ locations: ILocationsData[] }>(),
    devicesUpdated: props<{ devices: IThermalCamSettings[] }>(),
    devicesPointsUpdated: props<{ points: IGetCurrentCamPoint[] }>(),
    addDevicePoint: props<{
      cameraId: number;
      point: ISaveCamPointPostRequest;
    }>(),
    devicePointAddSuccess: props<{ point: ISaveThermalCamPoint }>(),
    updateDevicePoint: props<{
      cameraId: number;
      point: ISaveCamPointPutRequest;
    }>(),
    devicePointUpdateSuccess: props<{ point: ISaveThermalCamPoint }>(),
    variablesUpdated: props<{ variables: IVariableToDisplay[] }>(),
    activeLocationChanged: props<{ location: ILocationsData }>(),
    activeLocationRestored: props<{ location: ILocationsData }>(),
    activeLocationConfigRetrived: props<{
      config: ILocationConfiguration;
    }>(),
    variableConfigUpdated: props<{ variable: IVariableToDisplay }>(),
    startExportTask: props<{ params: ICSVExportRequest }>(),
    exportTaskStarted: props<{ task: ITask }>(),
    checkExportTaskState: emptyProps(),
    scheduleCheckExportTaskState: emptyProps(),
    exportTaskStateUpdated: props<{ state: IStatusCSVExportResponse }>(),
    deleteExportTask: emptyProps(),
    reload: emptyProps(),
    timezonesUpdated: props<{ timezones: ITimeZone[] }>(),
    userSettingsRetriveSuccess: props<{ settings: IUserSettingsGet }>(),
    userSettingsChanged: props<{ settings: IUserSettingsGet }>(),
    userSettingsChangeSuccess: emptyProps(),
    apiKeyRetriveSuccess: props<{ apiKey: string }>(),
    regenerateApiKey: emptyProps(),
    updateActiveLocationConfig: props<{ config: ILocationConfiguration }>(),
    activeLocationConfigUpdated: props<{ config: ILocationConfiguration }>(),
    addEmailAlert: props<{ emailAddress: string }>(),
    alertEmailAddSuccess: props<{ email: ILocationConfigurationEmail }>(),
    alertEmailRemoved: props<{ email: ILocationConfigurationEmail }>(),
  },
});
