export class StateUtils {
  static updateArrayItem<T>(
    array: T[] | undefined,
    predicate: (item: T) => boolean,
    update: Partial<T>,
  ) {
    if (!array) {
      return;
    }

    const itemIndex = array.findIndex(predicate) ?? -1;
    if (itemIndex < 0) {
      return;
    }

    array[itemIndex] = {
      ...array[itemIndex],
      ...update,
    };
  }
}
