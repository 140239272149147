/* eslint-disable @typescript-eslint/no-explicit-any */

import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { produce } from 'immer';
import _, { orderBy } from 'lodash';
import {
  IGetCurrentCamPoint,
  ILocationsData,
  IThermalCamSettings,
  ITimeZone,
  IUserSettingsGet,
  IVariableToDisplay,
  UserSettingsGet,
} from '../../api/api-sdk';
import { NodesActions } from '../../components/admin/nodes/state/nodes.actions';
import { ISigrowLocation } from '../../model/admin';
import { IConfiguredDevice, IDevice } from '../../model/dashboard';
import { ITask } from '../../model/task';
import { StateUtils } from '../../utils/state';
import { AdminActions } from './actions';

export interface IAdminState {
  locations: ILocationsData[];
  devices: IThermalCamSettings[];
  devicePoints: IGetCurrentCamPoint[];
  variables: IVariableToDisplay[];
  activeLocation: ISigrowLocation | undefined;
  exportTask: ITask | undefined;
  timezones: ITimeZone[];
  userSettings: IUserSettingsGet;
  apiKey: string;
  variablesOrder: string[];
}

export const initialState: IAdminState = {
  locations: [],
  devices: [],
  devicePoints: [],
  variables: [],
  activeLocation: undefined,
  exportTask: undefined,
  timezones: [],
  userSettings: new UserSettingsGet(),
  apiKey: '',
  variablesOrder: [
    'par',
    'par_top',
    'rad_top',
    'e_net',
    'par_bottom',
    'rad_bottom',

    'temp',
    'humid',
    'g_water',
    'vpd',
    't_dew',

    'soil_hum',
    'pore_ec',
    'soil_temp',

    'vpd_plant',
    'vpd_leaf',

    'dli',

    'vbat',
  ],
};

export const reducer = createReducer(
  initialState,
  on(AdminActions.locationsUpdated, (state, action) =>
    produce(state, (draft) => {
      draft.locations = [...action.locations];
    }),
  ),
  on(AdminActions.devicesUpdated, (state, action) =>
    produce(state, (draft) => {
      draft.devices = action.devices;
    }),
  ),
  on(AdminActions.devicesPointsUpdated, (state, action) =>
    produce(state, (draft) => {
      draft.devicePoints = action.points;
    }),
  ),
  on(AdminActions.devicePointAddSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.devicePoints.push({
        ...action.point,
        last_measured: '',
        value: 0,
      });
    }),
  ),
  on(AdminActions.devicePointUpdateSuccess, (state, action) =>
    produce(state, (draft) => {
      const index = draft.devicePoints.findIndex(
        (v) =>
          v.id === action.point.id &&
          v.thermal_cam_id === action.point.thermal_cam_id,
      );
      draft.devicePoints[index] = {
        ...draft.devicePoints[index],
        ...action.point,
      };
    }),
  ),
  on(AdminActions.variablesUpdated, (state, action) =>
    produce(state, (draft) => {
      draft.variables = action.variables;
    }),
  ),
  on(AdminActions.variableConfigUpdated, (state, action) =>
    produce(state, (draft) => {
      draft.variables[
        draft.variables.findIndex((v) => v.name === action.variable.name)
      ] = action.variable;
    }),
  ),
  on(AdminActions.exportTaskStarted, (state, action) =>
    produce(state, (draft) => {
      draft.exportTask = action.task;
    }),
  ),
  on(AdminActions.exportTaskStateUpdated, (state, action) =>
    produce(state, (draft) => {
      if (!draft.exportTask) {
        return;
      }
      draft.exportTask.state = action.state;
    }),
  ),
  on(AdminActions.deleteExportTask, (state) =>
    produce(state, (draft) => {
      draft.exportTask = undefined;
    }),
  ),
  on(AdminActions.timezonesUpdated, (state, action) =>
    produce(state, (draft) => {
      draft.timezones = action.timezones;
    }),
  ),
  on(
    AdminActions.userSettingsRetriveSuccess,
    AdminActions.userSettingsChanged,
    (state, action) =>
      produce(state, (draft) => {
        draft.userSettings = { ...draft.userSettings, ...action.settings };
      }),
  ),
  on(AdminActions.apiKeyRetriveSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.apiKey = action.apiKey;
    }),
  ),
  on(
    AdminActions.activeLocationChanged,
    AdminActions.activeLocationRestored,
    (state, action) =>
      produce(state, (draft) => {
        draft.activeLocation = {
          location: action.location,
          configuration: undefined,
        };
      }),
  ),
  on(
    AdminActions.activeLocationConfigRetrived,
    AdminActions.updateActiveLocationConfig,
    (state, action) =>
      produce(state, (draft) => {
        const locationIndex = draft.locations.findIndex(
          (l) => l.central_id === draft.activeLocation?.location?.central_id,
        );
        if (locationIndex < 0) {
          return;
        }
        const updatedLocation = {
          ...draft.locations[locationIndex],
          name: action.config.location_name ?? '',
        };
        draft.locations[locationIndex] = updatedLocation;
        draft.activeLocation = {
          location: updatedLocation,
          configuration: action.config,
        };
      }),
  ),
  on(AdminActions.alertEmailAddSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.activeLocation!.configuration!.alert_emails = [
        ...draft.activeLocation!.configuration!.alert_emails,
        action.email,
      ];
    }),
  ),
  on(AdminActions.alertEmailRemoved, (state, action) =>
    produce(state, (draft) => {
      _.remove(
        draft.activeLocation!.configuration!.alert_emails,
        (e) => e === action.email,
      );
    }),
  ),
  on(NodesActions.nodeUpdated, (state, action) =>
    produce(state, (draft) => {
      const update = { name: action.form.name };

      const remotes = draft.activeLocation?.location?.remotes;
      StateUtils.updateArrayItem(
        remotes,
        (r) => r.remote_id === action.nodeId,
        update,
      );

      const locationRemotes = draft.locations.find((l) =>
        l.remotes.some((r) => r.remote_id === action.nodeId),
      )?.remotes;
      StateUtils.updateArrayItem(
        locationRemotes,
        (r) => r.remote_id === action.nodeId,
        update,
      );
    }),
  ),
);

export const adminFeature = createFeature({
  name: 'admin',
  reducer,
  extraSelectors: ({
    selectAdminState,
    selectActiveLocation,
    selectTimezones,
    selectVariablesOrder,
    selectDevices,
  }) => ({
    selectAllDevices: createSelector(selectAdminState, (state: IAdminState) =>
      state.devices.map(
        (d) =>
          ({
            name: d.node_name,
            remote_id: d.remote_id_air,
            thermal_camera_id: d.tc_id,
            flower_recognition: d.flower_recognition,
            allowed_variables: [],
            timestamp_unix: d.timestamp_unix,
          }) satisfies IConfiguredDevice,
      ),
    ),
    selectDevicesForActiveLocation: createSelector(
      selectAdminState,
      (state: IAdminState) => getLocationDevices(state),
    ),
    selectDevicePointsById: (deviceId: number) =>
      createSelector(selectAdminState, (state: IAdminState) =>
        state.devicePoints.filter((dp) => dp.thermal_cam_id === deviceId),
      ),
    selectDevicePointsByIdList: (deviceIdList: number[]) =>
      createSelector(selectAdminState, (state: IAdminState) =>
        state.devicePoints.filter((dp) =>
          deviceIdList.includes(dp.thermal_cam_id),
        ),
      ),
    selectDeviceSettings: (deviceId: number) =>
      createSelector(selectAdminState, (state: IAdminState) =>
        state.devices.find((dv) => dv.tc_id === deviceId),
      ),
    selectVariables: createSelector(
      selectAdminState,
      selectVariablesOrder,
      (state: IAdminState, order: string[]) =>
        orderBy(state.variables, (v) => order.indexOf(v.name) ?? -1),
    ),
    selectLocationTimezone: createSelector(
      selectActiveLocation,
      selectTimezones,
      (activeLocation, timezones) => {
        const timezone = timezones.find(
          (tz) => tz.id === activeLocation?.configuration?.location_timezone_id,
        )?.name;
        return timezone
          ?.substring(0, timezone.indexOf(' ('))
          .replaceAll(' ', '_');
      },
    ),
    selectIsFlowerRecognitionAvailable: createSelector(
      selectActiveLocation,
      selectDevices,
      (activeLocation, devices) => {
        const locationCameraIds = new Set(
          activeLocation?.location?.cameras.map((c) => c.thermal_camera_id),
        );
        return devices.some(
          (d) => locationCameraIds.has(d.tc_id) && !!d.flower_recognition,
        );
      },
    ),
    selectIsBiomassAvailable: createSelector(
      selectAdminState,
      (state: IAdminState) =>
        getLocationDevices(state).some((d) => d.remote_id === 24883),
    ),
  }),
});

function getLocationDevices(state: IAdminState): IDevice[] {
  return [
    ...(state.activeLocation?.location?.remotes ?? []),
    ...(state.activeLocation?.location?.cameras ?? []),
  ] as IConfiguredDevice[];
}
